import React, { Component } from 'react';
import '../styles/App.css';
import { Link } from "react-router-dom";

class MainPage extends Component {

  render() {
    return (
      <div className="container">
        <div className="row">
        <div className="logo"></div>
        <div className="content">
          This system is for official Summit Polymers, Inc. use.  If you are not authorized to use this system, go away!
        </div>
        <div className="buttons">
          <Link to="GetFiles">
            <img src={require('../images/button_getfiles.png')} alt="" border="0" />
          </Link>
          <Link to="FileUploader">
            <img src={require('../images/button_sendfiles.png')} alt="" border="0" />
          </Link>
        </div>
        </div>
      </div>
    );
  }  
}

export default MainPage;