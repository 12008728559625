import React, { Component } from 'react';
import '../styles/App.css';
import { FilePond, File, registerPlugin } from '../filepond/react-filepond';
import '../filepond/filepond.min.css';
import FileDownloader from './FileDownloader';
import EmailForm from './EmailForm';
import {Config} from '../config';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

registerPlugin(FilePondPluginFileValidateSize);

function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return (s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4()).toUpperCase();
};

let varTimeOutMessage;

class FileUploader extends Component {
    constructor(props){
        super(props);
        
        if(sessionStorage.getItem('session-uniqueid') == null){
            sessionStorage.setItem('session-uniqueid', guid())
            props.showLogOutBtn();
        }
        
        this.state = {
            uniqueid: sessionStorage.getItem('session-uniqueid'), 
            online: false,
            files: [],
            uploadedfiles: 0,
            filesAdded: [],
            notUploadedFiles: []
        };

        this.serverConfig = {
            process: {
                url: '/api/upload',
                method: 'POST',
                headers: {
                    'uniqueid': this.state.uniqueid
                }
            },
            revert: {
              url: '/api/upload',
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json'
              }
          },
          load: null,
          restore: null,
          fetch: null
        };       
    }

    componentWillUnmount(){
        clearTimeout(varTimeOutMessage);
    }

    handleOnAddFile(error, file){ //On add new file       
        if(!error){
            let addedFiles = this.state.filesAdded;
            let newFile = {
                Name: file.filename,
                Uploaded: false
            };
            addedFiles.push(newFile)
            let uniqueFiles = [];
            addedFiles.forEach((sFile) => {
                if (!uniqueFiles.some(val => val.Name === sFile.Name)) {
                    newFile = {
                        Name: sFile.Name,
                        Uploaded: sFile.Uploaded
                    };
                    uniqueFiles.push(newFile);
                }
            });
            this.setState({
                filesAdded: uniqueFiles
            })   
        }
    }

    handleOnProcessFile(error, file){ //On done uploading file
        let addedFiles = this.state.filesAdded;
        if(!error){
            this.setState({
                uploadedfiles: this.state.uploadedfiles + 1
            });
            let uploadedFiles = [];
            addedFiles.forEach((sFile) => {
                if (!uploadedFiles.some(val => val.Name === sFile.Name)) {
                    
                    let uploadedFile = {
                        Name: sFile.Name,
                        Uploaded: sFile.Name === file.filename ? true : sFile.Uploaded
                    };
                    uploadedFiles.push(uploadedFile);
                }
            });
            this.setState({
                filesAdded: uploadedFiles
            })  
            this.showFileNotUploadedMessage();
        }
    }

    handleOnProcessFileProgress(){
        clearTimeout(varTimeOutMessage);
        this.setState({
            notUploadedFiles: []
        })
    }

    showFileNotUploadedMessage(){
        let addedFiles = this.state.filesAdded;

        let notUploadedFiles = [];
        addedFiles.forEach((sFile) => {
            if (sFile.Uploaded === false) {                
                let notUploadedFile = {
                    Name: sFile.Name,
                    Uploaded: sFile.Uploaded
                };
                notUploadedFiles.push(notUploadedFile);
            }
        });
        
        varTimeOutMessage = setTimeout(() => {
            this.setState({
                notUploadedFiles: notUploadedFiles
            })
        }, 2500);        
    }

    handleOnWarning(error, files){
        if(error != null && error.body === "Max files"){
            alert('To many files, max allowed ' + Config.maxFiles +'. Currently trying to upload ' + files.length)
        }
    }

    showFailedUploadSection(){
        let message = "";
        if(this.state.notUploadedFiles.length > 0){
            message = (<div className="col-6 mt-3">
            <div className="alert alert-danger" role="alert">
                <h4 className="alert-heading">Files not uploaded <span className="badge badge-danger">{this.state.notUploadedFiles.length}</span></h4>
                <hr />
                <ul>
                {
                    this.state.notUploadedFiles.map((file, index) => (
                        <li key={index}>{file.Name}</li>
                    ))
                }
                </ul>
            </div>
          </div>);
        }
        return message;
    }

    render() {   
      let addedFilesMessage = <h5>Added Files <span className="badge badge-light">{this.state.filesAdded.length}</span></h5>
  
      return (
        <div>
            <div className="row">   
              <div className="logo-send"></div>      
              <div className="col-12 send">
                <h6>Step 1: Add Files.</h6>	
                <ul>
                    <li>Click "Browse" below to select the files for this DropBox. Or Drag and Drop it on the box.</li>
                    <li>Files with special characters may not upload correctly, rename files if you have issues uploading.</li>
                    <li>When the files finish, they will appear in the "Uploaded Files" section at the bottom of the page.</li>
                    <li>"Uploaded Files" section displays the same info your recipient will see.  </li>
                    <li><strong><font color="red">The files will be deleted automatically after 14 days.</font></strong></li>
                </ul>   
            </div>  
            </div>
            <div className="row">                
              <div className={'mt-3 col-' + (this.state.notUploadedFiles.length > 0 ? '6' : '12') }>
              {addedFilesMessage}
              <FilePond ref={ref => this.pond = ref}
                allowFileSizeValidation = {true}
                maxFileSize = {Config.maxFileSize}
                allowMultiple={true} 
                maxParallelUploads = {Config.maxParallelUploads}
                maxFiles = {Config.maxFiles}
                name='file' 
                server = {this.serverConfig}
                onprocessfile={ this.handleOnProcessFile.bind(this) }
                onwarning={ this.handleOnWarning }
                onprocessfileprogress={ this.handleOnProcessFileProgress.bind(this) }
                onaddfile={ this.handleOnAddFile.bind(this) }
                onupdatefiles={(fileItems) => {
                  this.setState({
                      files: fileItems.map(fileItem => 
                        fileItem.file
                      )
                  });
                }}
              >
              {
                  this.state.files.map(file => (
                    <File key={file} src={file} origin="local" />              
                  ))                  
              }
              </FilePond>
              </div>    
              {this.showFailedUploadSection()}
            </div>
            <EmailForm uniqueid= {this.state.uniqueid} />
            <div className="row justify-content-center mt-3" >
                <h5>Uploaded Files</h5>
            </div>
            <FileDownloader uniqueid={this.state.uniqueid} uploadedfiles= {this.state.uploadedfiles}/>     
        </div>
      );
    }  
  }
  
  export default FileUploader;