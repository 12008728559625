import React, { Component } from 'react';
import '../styles/App.css';
import DownloadButton from './DownloadButton';

let timeOutVar;

class FileDownloader extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            lastModifiedDate: new Date()
        };
    }

    componentDidMount() {
        this.getListFromServer();
    }

    componentWillReceiveProps(props){
        if(props.uploadedfiles !== this.state.list.length){
            clearTimeout(timeOutVar);
            timeOutVar = setTimeout(
                this.getListFromServer.bind(this)
            ,500);
        }
    }

    componentWillUnmount(){
        clearTimeout(timeOutVar);
    }
    
    getListFromServer(){
        fetch('/api/list?uniqueid='+ this.props.uniqueid)
        .then(res => res.json())
        .then(
            data => this.setState({ 
                list: data.filesList,
                lastModifiedDate: data.lastModifiedDate
            })
        );
    }

    getDeletionDate(){
        const lastModified = new Date(this.state.lastModifiedDate);
        const deleteDate = Math.floor(lastModified.getTime() / 1000) + 1209600;   
        const today = Math.floor(new Date().getTime() / 1000);
        const remaining = deleteDate - today;
        const days = Math.floor(remaining/60/60/24);
        const hours = Math.floor((remaining - days*60*60*24)/60/60);
        return <font color="red"><b>!!! This DropBox will be deleted in {days} days and {hours} hours !!! </b></font>
    }

    render(){
        return(
            <ShowList data= {this.state.list} uniqueid = {this.props.uniqueid} getDeletionDate={this.getDeletionDate.bind(this)}/>
        )
    }
}

function ShowList(props){    
    var downloadUrl = '/api/download?uniqueid=' + props.uniqueid + '&file=';
    if (!props.data.length) {
        return(
            <div className="row">
                <p>The DropBox has not been created or no longer exists.</p>
            </div>
        )
      } else {
        return (
            <div className="row">
                {props.getDeletionDate()}
                <table className="table">   
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">File Name</th>
                        <th scope="col">Size</th>
                        <th scope="col">Type</th>
                        <th scope="col">Download</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.data.map((file, index) => (
                            <tr key={file.Name}>
                                <th>
                                    {index + 1}
                                </th> 
                                <td>
                                    {file.Name}
                                </td> 
                                <td>
                                    {Math.round(file.Size * 100) / 100 + ' MB'} 
                                </td> 
                                <td>
                                    {file.Type}
                                </td> 
                                <td>
                                    <DownloadButton Name={file.Name} downloadUrl = {downloadUrl} />
                                </td> 
                            </tr>                         
                        ))
                        }
                    </tbody>
                </table>
          </div>
        );
      }
}

export default FileDownloader;