import React, { Component } from 'react';
import '../styles/App.css';
import FileDownloader from './FileDownloader';
import * as qs from 'query-string';

class GetFiles extends Component {
  constructor(){
    super();
    this.state={
      uniqueid: null
    }
  }

  componentDidMount(){
    this.handleParams();
  }

  handleParams(){
    const params = qs.parse(this.props.location.search);
    if(params.code){
      this.setState({
        uniqueid: params.code
      });
    }
  }
  
  handleSubmit(event) {
    event.preventDefault();    
    const data = event.target.dropbox_code.value;    
    this.setState({
      uniqueid: data
    });
  }
  

  decideWhatToRender(){
    if(this.state.uniqueid === null){
      return (
        <div>
        <div className="logo-get"></div>
        <div className="row justify-content-center mt-3">
          Please enter the SPI DropBox code.
        </div>
        <div className="row">
          <div className="getcode">                  
            <form onSubmit={this.handleSubmit.bind(this)} method="post" className="form-inline justify-content-center"> 
              <div className="form-group">
                <input required className="form-control" name="dropbox_code" type="text" size="24"/>
                <button type="submit" className="btn btn-outline-success">Enter Code</button>
              </div>              
            </form>
          </div>
        </div>
      </div>      
      );
    } else {
      return <FileDownloader uniqueid= {this.state.uniqueid } />
    }
  }

  render() {
    return this.decideWhatToRender()
  }  
}


export default GetFiles;