import React, { Component } from 'react';

class DownloadButton extends Component {
  constructor(){
    super();
    this.state={
      downloaded: false
    }
  }
  toggleClass() {
    this.setState({ downloaded: true });
};

  render() {
    const {Name, downloadUrl} = this.props;
    return (
        <a id={'btnDown' + Name}
            className={'btn btn-sm btn-outline-' + (!this.state.downloaded ? 'primary': "secondary") } 
            href={downloadUrl + encodeURIComponent(Name)} 
            onClick={this.toggleClass.bind(this)} 
            download={Name}
        >
            {!this.state.downloaded ? 'Download': 'Redownload'}
        </a>
    );
  }  
}


export default DownloadButton;