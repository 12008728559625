import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import FileUploader from './FileUploader';
import GetFiles from './GetFiles';
import MainPage from './MainPage';
import Footer from '../footer';

class Layout extends Component {
    constructor(){
        console.log('Layout: constructor ', sessionStorage.getItem('session-uniqueid'))
        super();
        this.state = {
            showLogOut: sessionStorage.getItem('session-uniqueid') != null
        };
        this.showLogOutBtn =this.showLogOutBtn.bind(this);
    }    

    showLogOutBtn(){
        console.log('showLogOutBtn')     
        this.setState({
            showLogOut: true
        });
    }

    logOut(){
        console.log('logOut')        
        this.setState({
            showLogOut: false
        });
        sessionStorage.removeItem('session-uniqueid');
    }

    render(){
        return (<div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
                <a className="navbar-brand" href="/">
                <img src={require('../images/logo.png')} width="43" height="35" className="d-inline-block align-top" alt="" />
                    SPI Dropbox
                </a>
                <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                    <li className="nav-item active">
                        <a className="nav-link" href="/">Home</a>
                    </li>
                </ul>
                {this.state.showLogOut ? <button id="logOutBtn" onClick={() => this.logOut()} className="btn btn-dark my-2 my-sm-0">End Session</button> : ''}
            </nav>
        <div>
        <Router>
            <Route>
                <div className="container">
                    <Route exact path="/" component= {MainPage}></Route>
                    <Route path="/getfiles.php" component= {GetFiles}></Route>
                    <Route path="/GetFiles" component= {GetFiles}></Route>
                    <Route path="/FileUploader" component= {() => <FileUploader showLogOutBtn = {this.showLogOutBtn} />}></Route>
                    <Footer />
                </div>
            </Route>
        </Router>
        </div>
    </div>);
    }
}

export default Layout;