import React from 'react';

function Footer(){
    return (
        <footer >
            <div className=" text-center">© {new Date().getFullYear()} SUMMIT POLYMERS, INC. ALL RIGHTS RESERVED.
            </div>
        </footer>
    )
}

export default Footer;