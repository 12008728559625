import React, { Component } from 'react';
import {Config} from '../config';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';

class EmailForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            toEmails: []
        };
    }

    textareaValue = "You have received a Summit Polymers, Inc. DropBox code.\n\n"
                    + "To access the files in this DropBox directly, please go to\n"
                    + Config.serverURL +"/GetFiles?code=" + this.props.uniqueid
                    + "\n\nYour DropBox code is:\n" 
                    + this.props.uniqueid
                    + "\n" + Config.serverURL + "\n\n"
                    + "These files will be deleted in 14 days.";
    handleSubmit(event) {
        event.preventDefault();    
        const from = event.target.emailfrom.value;
        const body = event.target.emailBody.value;
        if(this.state.toEmails.length === 0){
            alert("Please provide at least one valid email address to send the email to.")
        }else{
            this.sendEmail(from, this.state.toEmails, body, this.props.uniqueid);
        }        
    }

    sendEmail(from, to, email, uniqueid) {
        return fetch("/api/sendemail", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ from, to, email, uniqueid })
        })
        .then(response => response.json())
        .then(data => {
            if(data.success === true){
                document.getElementById('logOutBtn').click(); 
            }else{
                alert("Something went wrong, the email could not be sent. Please try again, if the problem persists please contact support.")
            }
        });
    };

    render(){
        const { toEmails } = this.state.toEmails;

        return (
            <div className="row">
              <div className="col-12">
                  <div className="email">
                      <h3>Step 2: E-mail the Link.</h3>
                      <ul>
                          <li>Once all your files have been uploaded, fill out this form.</li>
                          <li>Please use <b>full email addresses</b>!  (example:  user@summitpolymers.com)</li>
                      </ul>
                      <form onSubmit={this.handleSubmit.bind(this)}  method="post">                        
                          <div className="form-group row">
                              <label htmlFor="emailfrom" className="col-sm-1 col-form-label" >From:</label>
                              <div className="col-sm-6">
                                  <input required type="email" className="form-control"  name="emailfrom" placeholder="From"/>
                              </div>
                          </div>
                          <div className="form-group row">
                              <label htmlFor="emailto" className="col-sm-1 col-form-label" >To:</label>
                              <div className="col-sm-6">
                                  
                                  <ReactMultiEmail
                                    placeholder="To"
                                    emails={toEmails}
                                    onChange={(_emails) => {
                                        this.setState({ toEmails: _emails });
                                    }}
                                    validateEmail={email => {
                                        return isEmail(email); // return boolean
                                    }}
                                    getLabel={(
                                        email,
                                        index,
                                        removeEmail
                                    ) => {
                                        return (
                                        <div data-tag key={index}>
                                            {email}
                                            <span data-tag-handle onClick={() => removeEmail(index)}>
                                            ×
                                            </span>
                                        </div>
                                        );
                                    }}
                                    />
                              </div>
                          </div>
                          <div className="form-group row">
                              <label htmlFor="emailBody" className="col-sm-1 col-form-label" >Body:</label>
                              <div className="col-sm-10">
                                  <textarea required className="form-control" name="emailBody" cols="50" rows="8" defaultValue={this.textareaValue}/>
                              </div>
                          </div>
                          <button type="submit" className="btn btn-primary">Send Email</button>
                      
                      </form>
                  </div>
              </div>
          </div>
    )};
}

export default EmailForm;